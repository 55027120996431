import React, { useContext } from "react";
// import { useStaticQuery, graphql } from "gatsby";
import SEO from "../components/seo";
import Words from "../components/Words";
import { WrapperContext } from "../components/Layout";

// const query = graphql`
//   query Site {
//     site {
//       buildTime(formatString: "YYYY-MM-DD")
//       siteMetadata {
//         siteTitle
//         siteDescription
//         siteUrl
//         siteBanner
//       }
//     }
//     settings: allGoogleFeuille4Sheet {
//       nodes {
//         metaKey
//         metaValue
//       }
//     }
//   }
// `;

const SiteIndex = () => {
  const { settings } = useContext(WrapperContext);
  // const { site, settings } = useStaticQuery(query);
  // const {
  //   buildTime,
  //   siteMetadata: { siteBanner },
  // } = site;

  const _getMetaValue = (meta) => {
    return settings.nodes.filter((el) => el.metaKey === meta)[0].metaValue;
  };
  // const _getSiteName = () => {
  //   return settings.nodes.filter((el) => el.metaKey === "siteName")[0]
  //     .metaValue;
  // };

  // const _getSiteDescription = () => {
  //   return settings.nodes.filter((el) => el.metaKey === "siteDescription")[0]
  //     .metaValue;
  // };
  // console.log(_getSiteName());

  return (
    <div>
      <SEO
        pageTitle={_getMetaValue("siteName")}
        pageDescription={_getMetaValue("siteDescription")}
        // pageImage={siteBanner}
        template={`template-page`}
      />

      <Words />
    </div>
  );
};

export default SiteIndex;
