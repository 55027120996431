import React, { useEffect, useState, useRef, useContext } from "react";
import clsx from "clsx";
import PubSub from "pubsub-js";
import { WrapperContext } from "./Layout";

const Dice = () => {
  const { settings } = useContext(WrapperContext);

  const [active, setActive] = useState();
  const [transform, setTransform] = useState();
  const [positions, setPositions] = useState({ left: "50%", top: "50%" });
  const [scale, setScale] = useState(1.2);

  const _getMetaValue = (meta) => {
    return settings.nodes.filter((el) => el.metaKey === meta)[0].metaValue;
  };

  let allVal = {
    x: 0,
    y: 0,
    z: 0,
  };

  const perFace = [
    [-0.1, 0.3, -1],
    [-0.1, 0.6, -0.4],
    [-0.85, -0.42, 0.73],
    [-0.8, 0.3, -0.75],
    [0.3, 0.45, 0.9],
    [-0.16, 0.6, 0.18],
  ];

  const setVal = (num) => {
    setTransform(`rotate3d(${perFace[num - 1]}, 180deg)`);
  };

  const setDice = () => {
    setTransform(`rotate3d(${Object.values(allVal)}, 180deg)`);
  };

  const timeOut = _getMetaValue("diceTimeOut");
  const interval = _getMetaValue("diceInterval");
  const timeOutRef = useRef();
  const intervalRef = useRef();
  console.log("timeOut", timeOut);
  console.log("interval", interval);
  useEffect(() => {
    if (window.innerWidth < 1080 && "ontouchstart" in window) {
      setScale(0.8);
    }
    _setRandomPos();

    //timer
    // if ("ontouchstart" in window) return;

    // document.addEventListener("mousemove", _stopUserInteract);
    _triggerAutoplay();

    return () => {
      // document.removeEventListener("mousemove", _triggerAutoplay);
      // _stop();
    };
  }, []);

  const _triggerAutoplay = () => {
    _stop();

    clearTimeout(timeOutRef.current);
    timeOutRef.current = setTimeout(() => {
      _start();
    }, timeOut);
  };

  const _start = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      // console.log("_start _updateDice");
      _updateDice();
    }, interval);
  };

  const _stop = () => {
    clearTimeout(intervalRef.current);
  };

  const _setRandomPos = () => {
    const minX = window.innerWidth / 4;
    const maxX = (window.innerWidth / 4) * 3;
    const minY = window.innerHeight / 4;
    const maxY = (window.innerHeight / 4) * 3;

    const randX = random(minX, maxX);
    const randY = random(minY, maxY);
    setPositions({ left: randX, top: randY });
  };

  const random = (min, max) => {
    return Math.random() * (max - min) + min;
  };

  const _updateDice = () => {
    // _stop();
    const diceVal = Math.round(Math.random() * 5) + 1;
    setVal(diceVal);
    setActive(true);

    setTimeout(() => {
      PubSub.publish("NEW_WORD");
    }, 1000);

    setTimeout(() => {
      setActive(false);
      _setRandomPos();
    }, 2000);
  };

  return (
    <div
      className='diceWrap'
      onClick={_updateDice}
      style={{
        transform: `translate(${positions.left}px, ${positions.top}px) scale(${scale})`,
      }}>
      <div
        className={clsx("dice ", active ? "throw" : "")}
        style={{
          transform: transform,
        }}>
        <div className='diceFace front'></div>
        <div className='diceFace up'></div>
        <div className='diceFace left'></div>
        <div className='diceFace right'></div>
        <div className='diceFace bottom'></div>
        <div className='diceFace back'></div>
      </div>
    </div>
  );
};

export default Dice;
