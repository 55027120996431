import React, { useEffect, useState, useContext } from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import PubSub from "pubsub-js";
import Dice from "./Dice";
import { DarkModeContext } from "./Layout";

export const query = graphql`
  query {
    allGoogleFeuille2Sheet {
      nodes {
        word1
        word2
        color1
        color2
      }
    }
  }
`;

const Container = styled.section`
  width: 100vw;
  overflow: hidden;
  height: var(--app-height);
  .wrap {
    transform: translateY(-0.3em);
    @media all and (max-width: 600px) {
      // height: 100%;
      // overflow: hidden;
      text-overflow: ellipsis;
      // -webkit-text-orientation: mixed;
      // text-orientation: mixed;
      // -ms-writing-mode: tb-lr;
      // writing-mode: vertical-lr;
      transform-origin: center;
      // transform: rotate(180deg);
      transform: rotate(-90deg);
    }
  }
`;
const Word = styled.div`
  font-size: 19vw;
  line-height: 0.89;
  text-align: center;
  letter-spacing: -0.01em;
  transition: color 0.4s ease;
  span {
    white-space: nowrap;
  }
  @media all and (max-width: 600px) {
    font-size: 17vh;
  }
`;

const Words = () => {
  const { allGoogleFeuille2Sheet } = useStaticQuery(query);
  const { dark } = useContext(DarkModeContext);
  const [ready, setReady] = useState();

  const _random = () => {
    const len = allGoogleFeuille2Sheet.nodes.length;
    const rand = Math.floor(Math.random() * len);
    return rand;
  };
  const [index, setIndex] = useState(_random());

  // const _setRandomWord = () => {
  //   // setIndex(_random());
  // };

  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 500);
    const token = PubSub.subscribe("NEW_WORD", (e) => {
      console.log(e);
      setIndex(_random());
    });

    return () => PubSub.unsubscribe(token);
  }, []);

  // const _renderSplitText = (str) => {
  //   const colors = ["red", "green", "blue"];
  //   const parts = str.split("");
  //   return parts.map((el, i) => (
  //     <span key={i} className={colors[i % 3]}>
  //       {el}
  //     </span>
  //   ));
  // };

  return (
    <Container className='p-md md:p-lg h-screen wrap flex justify-center items-center'>
      <div className='wrap'>
        <Word
          className='word '
          style={{
            color: dark
              ? allGoogleFeuille2Sheet.nodes[index].color2
              : allGoogleFeuille2Sheet.nodes[index].color1 || "black",
          }}>
          <span>{allGoogleFeuille2Sheet.nodes[index].word1}</span>
        </Word>
        <Word
          className='word'
          style={{
            color: dark
              ? allGoogleFeuille2Sheet.nodes[index].color2
              : allGoogleFeuille2Sheet.nodes[index].color1 || "black",
          }}>
          <span>{allGoogleFeuille2Sheet.nodes[index].word2}</span>
        </Word>
      </div>
      {ready && <Dice />}
    </Container>
  );
};

export default Words;
